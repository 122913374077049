@font-face {
  font-family: 'GothamRoundedHTF';
    src: url('/fonts/GothamRoundedHTF/GothamRnd-Book.otf');
    src: url('/fonts/GothamRoundedHTF/GothamRnd-BookItal.otf');
    src: url('/fonts/GothamRoundedHTF/GothamRnd-Bold.otf');
    src: url('/fonts/GothamRoundedHTF/GothamRnd-BoldItal.otf');
    src: url('/fonts/GothamRoundedHTF/GothamRnd-Light.otf');
    src: url('/fonts/GothamRoundedHTF/GothamRnd-LightItal.otf');
    src: url('/fonts/GothamRoundedHTF/GothamRnd-MedItal.otf');
    src: url('/fonts/GothamRoundedHTF/GothamRnd-Medium.otf');
}

.ant-tabs-tab {
  width: 120px;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.site-calendar-customize-header-wrapper {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

/* .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 'bold';
  color: #6283FA; /////// PRIMARY_PURPLE
}
*/

body {
  margin: 0;
  font-family: 'GothamRoundedHTF';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
